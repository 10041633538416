#serviceList {
    background-color: white !important;
    justify-content: center !important;
    color: black !important;
}

#serviceHead {
    text-align: center;
    font-size: xx-large;
    color: white;
    margin-top: 55px;
}

#servicePage {
    margin-left: 50px;
    margin-right: 50px;
    color: black !important;
}

#fullLogo {
  height: 200px !important;
  justify-content: center !important;
  align-items: center !important;
}

#trainingDesc {
    color: black;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: larger;
    border: 3px solid white;
    background-color:#E2FCEF ;
    margin-right: 25px;
    margin-left: 25px;
    box-shadow: rgb(100, 37, 37) 0px 20px 30px -10px;
    margin-bottom: 30px;
}

#evalutions {
    font-weight: bolder;
    color: #DD614A;
    text-align: center;
    font-size: larger;
}

span {
    color: rgb(59, 172, 59);
}


/* Style the accordion section */
.accordion__section {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Barlow', sans-serif !important
  }
  
  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color: #E2FCEF;
    color: black;
    cursor: pointer;
    padding: 35px;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
    font-family: 'Barlow', sans-serif !important
  }
  
  
  /* Style the accordion content title */
  .accordion__title {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    width: 1000px;
  }
  
  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }
  
  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }
  
  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: auto;
    font-family: 'Barlow', sans-serif !important
    /* transition: max-height 0.6s ease; */
  }
  
  /* Style the accordion content text */
  .accordion__text {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }

  h6 {
    color: black !important;
    font-weight: bolder;
  }

  Accordian.Header {
    font-family: 'Barlow', sans-serif !important
  }