#caption {
    margin-top: 250px !important;
  }
  
  .legend {
    font-size: 14px !important;
    background-color: #6B2737 !important;

  }
  
  #projPhoto {
    size: 800px !important;
}
img {
    box-shadow: rgba(#6B2737) 0px 22px 70px 4px;
    max-height: 650px;
    max-width: 650px !important;
}

#photosHead {
  margin-top: 55px;
  
}

#clientPhoto {
  color: white;
  text-align: center;
  margin-bottom: 75px;
}